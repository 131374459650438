





























import { Component, Watch, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import moment from 'moment';

@Component
export default class DutyTableComponent extends BaseComponent {
    @Prop()
    dutyData: Array<any>;
    @Prop()
    selectedMonth: string;

    // 选中信息
    selectedData: any = undefined;
    selectedDay: any = undefined;

    get Weekend() {
        return ['星期六', '星期日'];
    }

    get DayWeeks() {
        let dayWeeks = [];

        const selectMonthMoment = moment(this.selectedMonth, 'YYYY-MM');
        const days = selectMonthMoment.daysInMonth();

        for (let day = 1; day <= days; day++) {
            let column = {
                day: day.toString(),
                week: this.getWeekDay(moment(selectMonthMoment).date(day).format('YYYY-MM-DD'))
            };
            dayWeeks.push(column);
        }
        return dayWeeks;
    }

    @Watch('selectedMonth')
    update(newVal) {
        // 重新获取数据
    }

    created() {
        // 获取排版管理详情
    }

    getWeekDay(date) {
        let week = moment(date, 'YYYY-MM-DD').day();
        switch (week) {
            case 1:
                return '星期一';
            case 2:
                return '星期二';
            case 3:
                return '星期三';
            case 4:
                return '星期四';
            case 5:
                return '星期五';
            case 6:
                return '星期六';
            case 0:
                return '星期日';
        }
    }

    getDutyInfo(data, day) {
        let dutyInfo = { shiftId: '', shiftName: '未排班' };
        if (data && data.shiftDates) {
            const match = data.shiftDates.filter(item => { return item.day === day.day; });
            if (match && match.length > 0) {
                dutyInfo = match[0];
            }
        }
        return dutyInfo;
    }

    editDuty(data, day) {
        const dutyInfo = this.getDutyInfo(data, day);
        this.$emit('cellClicked', data, day, dutyInfo);
    }
}
